import { useEffect, useMemo } from 'react'

import { Divider, Grid } from '@mui/material'
import { Box } from '@mui/system'
import { Form, Formik } from 'formik'

import { ASYNC_SEARCH_LIMIT, NOTIFICATION_TYPE } from '~/common/constants'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import { Field } from '~/components/Formik'
import LV from '~/components/LabelValue'
import Loading from '~/components/Loading'
import { STATUS_VALUE } from '~/modules/master/constants'
import {
  apiGetListFactoryList,
} from '~/modules/master/redux/apis/factory'
import { apiUpdateAddSite } from '~/modules/master/redux/apis/profile-custom'
import useProfileCustom from '~/modules/master/redux/hooks/useProfileCustom'
import { convertFilterParams } from '~/utils'
import addNotification from '~/utils/toast'

import { validationSchema } from './schema'

const UpdateSite = ({ t, open, data = {}, onClose = () => {} }) => {
  const {
    data: {
      detailProfileCustom: { data: detailProfileCustom },
    },
    actions: { actGetDetailProfileCustom, actGetDetailProfileCustomReset },
  } = useProfileCustom()

  useEffect(() => {
    if (data?.id) {
      actGetDetailProfileCustom(data?.id)
    }

    return () => {
      actGetDetailProfileCustomReset()
    }
  }, [data])

  const initialValues = useMemo(() => {
    if (detailProfileCustom?.factoryCode) {
      return {
        factory: {
          code: detailProfileCustom.factoryCode,
          name: detailProfileCustom.factoryName,
          id: detailProfileCustom.factoryId,
        },
      }
    }

    return { factory: undefined }
  }, [detailProfileCustom])

  const handleUpdateSite = async (val, setFieldValue) => {
    const body = { id: data?.id, factoryId: val.factory?.id }
    setFieldValue('isLoading', true)
    const res = await apiUpdateAddSite(body)
    if (res.statusCode === 200) {
      addNotification(
        'profileCustom.notification.updateSiteSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      )
    } else {
      addNotification(res.message, NOTIFICATION_TYPE.ERROR)
      setFieldValue('isLoading', false)
    }

    handleClose()
  }

  const handleClose = () => {
    actGetDetailProfileCustomReset()
    onClose()
  }

  return (
    <Dialog
      open={open}
      title={t('profileCustom.updateSiteForEmp')}
      onCancel={handleClose}
      maxWidth="lg"
      noBorderBottom
    >
      <Formik
        onSubmit={(val, { setFieldValue }) =>
          handleUpdateSite(val, setFieldValue)
        }
        initialValues={initialValues}
        // validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {({ resetForm, values }) => (
          <Form>
            <Grid
              container
              rowSpacing={4 / 3}
              sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
              dateSyn
            >
              <Grid item lg={4} xs={12}>
                <LV
                  label={t('profileCustom.code')}
                  labelColor="#222222"
                  valueSx={{ fontWeight: 'bold' }}
                  value={data?.code}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <LV
                  label={t('profileCustom.route')}
                  labelColor="#222222"
                  valueSx={{ fontWeight: 'bold' }}
                  value={data?.nameRoute}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <Field.Autocomplete
                  label={t('profileCustom.updateSite')}
                  name="factory"
                  placeholder={t('profileCustom.updateSite')}
                  asyncRequest={(s) =>
                    apiGetListFactoryList({
                      keyword: s,
                      limit: ASYNC_SEARCH_LIMIT,
                      filter: convertFilterParams({
                        isActive: STATUS_VALUE.active,
                      }),
                    })
                  }
                  asyncRequestHelper={(res) => res?.data?.items}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(opt, val) => opt?.code === val.code}
                  labelSx={{ fontWeight: 'bold' }}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <LV
                  label={t('profileCustom.nameEmp')}
                  labelColor="#222222"
                  valueSx={{ fontWeight: 'bold' }}
                  value={data?.name}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <LV
                  label={t('profileCustom.departurePoint')}
                  labelColor="#222222"
                  valueSx={{ fontWeight: 'bold' }}
                  value={data?.namePickupPoint}
                />
              </Grid>
              <Grid item lg={4} xs={12} />
              <Grid item lg={4} xs={12}>
                <LV
                  label={t('profileCustom.codeAttendance')}
                  labelColor="#222222"
                  valueSx={{ fontWeight: 'bold' }}
                  value={data?.codeAttendance}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <LV
                  label={t('profileCustom.timeDeparture')}
                  labelColor="#222222"
                  valueSx={{ fontWeight: 'bold' }}
                  value={data?.timeDeparture}
                />
              </Grid>
              <Grid item lg={4} xs={12}></Grid>
              <Grid item lg={4} xs={12}>
                <LV
                  label={t('profileCustom.orgStructure')}
                  labelColor="#222222"
                  valueSx={{ fontWeight: 'bold' }}
                  value={data?.orgStructure}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <LV
                  label={t('profileCustom.timeArrival')}
                  labelColor="#222222"
                  valueSx={{ fontWeight: 'bold' }}
                  value={data?.timeArrival}
                />
              </Grid>
              <Grid item lg={4} xs={12}></Grid>
            </Grid>
            <Divider sx={{ marginTop: 1 }} />
            <Box sx={{ padding: 3 }}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="button"
                    sx={{ marginRight: '10px' }}
                    color="grayF4"
                    onClick={resetForm}
                  >
                    {t('actionBar.cancel')}
                  </Button>
                  <Button type="submit" sx={{ marginRight: '10px' }}>
                    {t('actionBar.save')}
                  </Button>
                </Box>
              </Grid>
            </Box>
            <Loading open={values.isLoading}></Loading>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default UpdateSite
